import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import axiosInstance from 'utils/axios.config';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
const storage = JSON.parse(sessionStorage.getItem('usersInfo'));
const initialEventDetails = sessionStorage.getItem('eventDetails') || '';
const guest = JSON.parse(sessionStorage.getItem('guest'));
const settlementId = JSON.parse(sessionStorage.getItem('settlementId'));
const LoanEmiId = JSON.parse(sessionStorage.getItem('emiId'));
const viewIdNo = JSON.parse(sessionStorage.getItem('viewId'));
export const AppContext = createContext();
export const AppContextProvider = ({ children }) => {
  const [user, setUser] = useState(storage);
  const [checkPerson, setCheckPerson] = useState(guest);
  const [allEvent, setAllEvent] = useState([]);
  const [eventId, setEventId] = useState('');
  const [eventDetails, setEventDetails] = useState(initialEventDetails || null);
  const [guestLists, setGuestLists] = useState([]);
  const [invitationId, setInvitationId] = useState(initialEventDetails || null);
  const [eventGuest, setEventGuest] = useState([]);
  const [inviteGuest, setInviteGuest] = useState([]);
  const [error, setError] = useState('');
  const [iouAdjustmentId, setIouAdjustmentId] = useState(settlementId);
  const [adjustment, setAdjustment] = useState('');
  const [iou, setIou] = useState('');
  const [emiId, setEmiId] = useState(LoanEmiId);
  const [notifications, setNotifications] = useState([]);
  const [shortNotifications, setShortNotifications] = useState([]);
  const [readAllData, setReadAllData] = useState(null);
  const [count, setCount] = useState(null);
  const [country, setCountry] = useState([]);
  const [leave, setLeave] = useState([]);
  const [empPayroll, setEmpPayroll] = useState('');
  const [profile, setProfile] = useState('');
  const [booking, setBooking] = useState('');
  const [selectBranchId, setSelectBranchId] = useState('');
  const [serialMessage, setSerialMessage] = useState('');
  const dataLoaded = useRef(false);
  const [viewId, setViewId] = useState(viewIdNo);
  const [viewDetails, setViewDetails] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(true);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [doctor, setDoctor] = useState([]);
  const [department, setDepartment] = useState([]);
  const [activeDoctorId, setActiveDoctorId] = useState('');
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [selectedDrFirstName, setSelectedDrFirstName] = useState('');
  const [selectedDrLastName, setSelectedDrLastName] = useState('');
  const [selectedDrImage, setSelectedDrImage] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [chamberSlotId, setChamberSlotId] = useState('');
  const [chamId, setChamId] = useState('');
  const [chamBranchId, setChamBranchId] = useState('');
  const [chamSlotId, setChamSlotId] = useState('');
  const [chamberScheduleBranch, setChamberScheduleBranch] = useState('');
  const [patientName, setPatientName] = useState('');
  const [patientPhone, setPatientPhone] = useState('');
  const [agentProfile, setAgentProfile] = useState('');
  const [propertyAgent, setPropertyAgent] = useState([]);
  const [ownerPortfolio, setOwnerPortfolio] = useState([]);
  const [ownerInfoLists, setOwnerInfoLists] = useState({});
  const [ownerInfo, setOwnerInfo] = useState([]);
  const [loading, setLoading] = useState(false);


  const [dhdbrids, setDhdbrids] = useState('');
  const [hdrids, setDdrids] = useState('');
  const [propertyLists, setPropertyLists] = useState([]);
  const [selectedTime, setSelectedTime] = useState({
    startTime: '',
    endTime: ''
  });
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const [appTypes, setAppTypes] = useState('myself');
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const updateTableData = (newData) => {
    setTableData(newData);
  };

  useEffect(() => {
    if (!dataLoaded.current) {
      async function fetchData() {
        try {
          const response = await axios.get('https://api.hellokompass.com/country');
          setCountry(response.data.data);
          dataLoaded.current = true;
        } catch (error) {
          console.error(error);
        }
      }
      fetchData();
    }
  }, [dataLoaded, country]);

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response) {
        const status = error.response.status;
        if (status === 400 && error.response.data.code === 303) {
          navigate('/login');
        }
      }
      return Promise.reject(error);
    }
  );



const [selectedValue, setSelectedValue] = useState(""); 
const [hdbrids, setHdbrids] = useState('');
const [hdids, setHdids] = useState('');

const handleSelectChange = (event) => {
  const value = event.target.value; 
  setSelectedValue(value);

  if (value) {
    const [newHdbrid, newHdid] = value.split(','); 
    setHdbrids(newHdbrid);
    setHdids(newHdid);
  }
};


  const handleBranchSelectChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue) {
      const [hdbrid, drid] = selectedValue.split(',');
      setDhdbrids(hdbrid);
      setDdrids(drid);
    } else {
      <></>;
    }
  };



  return (
    <AppContext.Provider
      value={{
        tableData,
        updateTableData,
        country,
        setCountry,
        error,
        setError,
        user,
        setUser,
        profile,
        setProfile,
        empPayroll,
        setEmpPayroll,
        eventId,
        setEventId,
        invitationId,
        setInvitationId,
        eventDetails,
        setEventDetails,
        eventGuest,
        setEventGuest,
        guestLists,
        setGuestLists,
        inviteGuest,
        setInviteGuest,
        leave,
        setLeave,
        emiId,
        setEmiId,
        shortNotifications,
        setShortNotifications,
        notifications,
        setNotifications,
        readAllData,
        setReadAllData,
        count,
        setCount,
        iouAdjustmentId,
        setIouAdjustmentId,
        adjustment,
        setAdjustment,
        iou,
        setIou,
        checkPerson,
        setCheckPerson,
        allEvent,
        setAllEvent,
        handleTogglePassword,
        password,
        setPassword,
        booking,
        setBooking,
        viewId,
        setViewId,
        viewDetails,
        setViewDetails,
        selectBranchId,
        setSelectBranchId,
        doctor,
        setDoctor,
        selectedDoctor,
        setSelectedDoctor,
        activeDoctorId,
        setActiveDoctorId,
        selectedTime,
        setSelectedTime,
        department,
        setDepartment,
        handleSelectChange,
        selectedValue,
        hdids,
        setHdids,
        hdbrids,
        setHdbrids,
        selectedDrLastName,
        setSelectedDrLastName,
        selectedDrFirstName,
        setSelectedDrFirstName,
        selectedDrImage,
        setSelectedDrImage,
        selectedDepartment,
        setSelectedDepartment,
        date,
        setDate,
        handleBranchSelectChange,
        hdrids,
        setDdrids,
        dhdbrids,
        setDhdbrids,
        chamberSlotId,
        setChamberSlotId,
        serialMessage,
        setSerialMessage,
        chamId,
        setChamId,
        chamBranchId,
        setChamBranchId,
        chamberScheduleBranch,
        setChamberScheduleBranch,
        chamSlotId,
        setChamSlotId,
        appTypes,
        setAppTypes,
        patientName,
        setPatientName,
        patientPhone,
        setPatientPhone,
        agentProfile,
        setAgentProfile,
        propertyAgent,
        setPropertyAgent,
        ownerPortfolio,
        setOwnerPortfolio,
        ownerInfo,
        setOwnerInfo,
        loading,
        setLoading,
        ownerInfoLists,
        setOwnerInfoLists,
        propertyLists,
        setPropertyLists
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
